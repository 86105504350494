.modal-loading-contract {
  .ant-modal-content {
    background-color: $color_white;
    border: 1px solid #343434;

    .ant-modal-body {
      padding: 18px 0px 32px 0px;

      p {
        margin: 0px;
      }

      img {
        width: 62px;
        margin: 22px 0px;
      }

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .title {
        text-align: center;
        font-size: 32px;
        font-weight: 400;
        line-height: 40px;
        color: $color-text_content;
      }

      .content {
        margin-top: 16px;
        color: $color-text_content;
        text-align: center;
        line-height: 21px;
      }
    }
  }
}

.popup-sign {
  .title {
    font-size: 24px !important;
    text-align: center;
  }

  .ant-modal-body {
    padding: 14px 0 !important;
  }
}

@-webkit-keyframes rotating

/* Safari and Chrome */
  {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rotating {
  -webkit-animation: rotating 1s linear infinite;
  -moz-animation: rotating 1s linear infinite;
  -ms-animation: rotating 1s linear infinite;
  -o-animation: rotating 1s linear infinite;
  animation: rotating 1s linear infinite;
}