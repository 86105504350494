.my-nft {
  margin-bottom: 40px;
  &__title {
    font-size: 32px;
    font-weight: 700;
    text-align: center;
  }
  &__search {
    margin: 0 auto;
    height: 44px;
    min-width: 246px;
    max-width: 525px;
    @media (max-width: $breakpoint_mobile_max) {
      width: 100%;
    }
    .ant-input-affix-wrapper {
      &-focused {
        box-shadow: none;
      }
      z-index: 0;
      height: 100%;
      border-radius: 20px;
      background-color: $color_white;
      border: 1px solid $color_athens_gra;
      input {
        background: transparent;
        color: $color-text_content;
      }
    }
  }
  &__pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__total {
      font-weight: 700;
    }
  }
  .list-nft {
    margin-top: 30px;
  }
}
