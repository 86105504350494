.token {
  height: 100%;
  max-width: 1210px;
  margin: 0px auto;

  .list-token {
    margin-bottom: 86px;

    .ant-collapse-item {
      margin-bottom: 12px;
    }

    &__tabs {
      .ant-tabs-nav {
        padding: 0 20px;

        &::before {
          content: none;
        }

        &-wrap {
          position: relative;
          border-bottom: 1px solid $color_athens_gra;
        }

        .ant-tabs-tab {
          &-active {
            .ant-tabs-tab-btn {
              color: $color_primary !important;
            }
          }

          .ant-tabs-tab-btn {
            color: $color_text-content;
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
          }
        }

        .ant-tabs-ink-bar {
          height: 3px;
          background-color: $color_placeholder;
        }
      }
    }
  }

  .search-token-list {
    padding: 0 20px;
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $breakpoint_mobile_max) {
      padding: 0px 14px;
    }

    @media (max-width: 813px) {
      flex-direction: column-reverse;
      align-items: stretch;
    }

    @media (max-width: $breakpoint_mobile_max) {
      flex-direction: column-reverse;
      align-items: flex-start;
    }

    flex-wrap: wrap;
    // row-gap: 12px;

    .search-left {
      display: flex;
      align-items: center;

      @media(max-width: 1033px) {
        margin-bottom: 12px;
      }

      @media (max-width: 812px) {
        margin-top: 12px;
        margin-bottom: 0px;
      }

      @media (max-width: $breakpoint_tablet_max) {
        display: block;
      }

      @media (max-width: 369px) {
        width: 100%;
      }

      .switch-wrap {
        margin-right: 18px;

        @media (max-width: $breakpoint_tablet_max) {
          float: left;
          margin-bottom: 12px;
          margin-right: 12px;
        }

        @media(max-width: $breakpoint_mobile_max) {
          &:nth-child(2) {
            margin-right: 0px;
          }
        }

        @media (max-width: 369px) {
          float: inherit;
        }

        @media (max-width: 385px) {
          margin-right: 42px;
          width: 100%;

          .ant-radio-group {
            width: 100%;

            .ant-radio-button-wrapper {
              width: 50%;
              text-align: center;

              span {
                justify-content: center;
                font-size: 16px;

                @media (max-width: $breakpoint_tablet_max) {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }

      .staking-pool {
        color: $color_black_1;
        font-size: 16px;
        line-height: 24px;
        // display: flex;
        align-items: center;
        width: fit-content;

        .ant-switch {
          background: $color_support;
          margin-right: 12px;
        }

        .ant-switch-checked {
          background: $color_pink_gradient;
        }
      }
    }

    .search-right {
      height: 44px;
      min-width: 246px;
      max-width: 525px;

      @media (max-width: $breakpoint_mobile_max) {
        width: 100%;
      }

      .ant-input-affix-wrapper {
        &-focused {
          box-shadow: none;
        }

        z-index: 0;
        height: 100%;
        border-radius: 20px;
        background-color: $color_white;
        border: 1px solid $color_athens_gra;

        input {
          background: transparent;
          color: $color-text_content;
        }
      }
    }
  }

  .ant-collapse {
    background: transparent;
    border: none;
    padding: 0 20px;

    @media (max-width: $breakpoint_mobile_max) {
      padding: 0px 14px;
    }

    .ant-collapse-item {
      border: none;
      box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.05);

      &-active {
        background-color: $color_white;
        border-radius: 16px;
        box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.05);

        .ant-collapse-header {
          background-size: 0 !important;
          border-bottom-left-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
          border-top-left-radius: 16px !important;
          border-top-right-radius: 16px !important;
          box-shadow: none !important;
          border-bottom: 1px solid $color_athens_gra;
        }
      }

      .ant-collapse-header {
        display: flex;
        padding: 0;
        overflow: scroll;
        height: 120px;
        background-color: $color_white;
        border-radius: 16px;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }

        @media (max-width: $breakpoint_tablet_max) {
          font-size: 12px;
          line-height: 20px;
        }

        .item-view-list {
          display: flex;
          justify-content: start;
          align-items: center;
          width: 100%;
          color: $color_porcelain;
          font-size: 16px;
          line-height: 24px;
          overflow-x: scroll;
          padding: 34px 14px 38px !important;
          scrollbar-width: none;

          @media (max-width: $breakpoint_tablet_max) {
            padding: 34px 14px !important;
          }

          p {
            margin: 0;
          }

          @media (max-width: $breakpoint_tablet_max) {
            font-size: 12px;
            line-height: 20px;
          }

          &::-webkit-scrollbar {
            display: none;
          }

          &.keep-scrolling {
            -ms-overflow-style: none;
            /* IE and Edge */
            scrollbar-width: none;
            /* Firefox */
          }

          @media (max-width: $breakpoint_tablet_max) {
            &>img {
              width: 38px;
            }
          }

          .detail-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            min-width: 982px;

            .name-item {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              justify-content: space-between;
              font-size: 16px;
              line-height: 24px;
              font-weight: 700;
              color: $color-text_content;
              width: 140px;

              span {
                flex-basis: 60%;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }

              .view-nft {
                color: $color_primary;
                text-decoration: underline;
                font-size: 12px;
                font-weight: 400;
              }
            }

            .apr {
              width: 85px;

              .content span {
                width: 5rem;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }
            }

            .end {
              width: 96px;
            }

            .start {
              width: 102px;

              @media (max-width: $breakpoint_tablet_max) {
                max-width: 72px;
              }

              .content {
                max-width: 100%;
                display: block !important;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }
            }

            .duration {
              width: 110px;
            }

            .liquidity {
              width: 130px;
            }

            .view-title {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: center;

              p {
                margin: 0px;
              }

              .title {
                color: $color-text_content;
                line-height: 24px;
                font-size: 14px;
                font-weight: 700;

                @media (max-width: $breakpoint_tablet_max) {
                  font-size: 10px;
                  line-height: 15px;
                }
              }

              .content {
                display: flex;
                align-items: center;
                width: 100%;
                color: $color_primary;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;

                @media (max-width: $breakpoint_tablet_max) {
                  font-size: 12px;
                  line-height: 20px;
                }
              }
            }

            .detail {
              .title {
                color: $color_secondary;
                font-weight: 400;
              }

              align-items: center;
            }

            .contract {
              .content {
                text-decoration: underline;
                font-weight: 400;
              }
            }
          }
        }
      }

      .ant-collapse-content {
        background: $color_white;
        border: none;
        border-bottom-right-radius: 16px;
        border-bottom-left-radius: 16px;

        .input {
          margin-bottom: 16px;
        }

        .content-item {
          background-color: $color_white;
          padding: 32px 28px;

          @media (max-width: $breakpoint_desktop_max) {
            padding: 32px 12px;
          }

          @media (max-width: $breakpoint_mobile_max) {
            padding: 32px 22px;
            background-image: none;
          }

          .content--left {
            border-right: 1px solid $color_athens_gra;
            padding-right: 32px;

            @media (max-width: $breakpoint_desktop_max) {
              padding-right: 12px;
            }

            @media (max-width: $breakpoint_mobile_max) {
              width: 100%;
            }

            @media (max-width: 767px) {
              border: none;
              padding-right: 0px;
              margin-bottom: 12px;
            }

            form {
              height: 100%;
            }

            .stake {
              display: grid;
              grid-template-columns: repeat(2, minmax(0, 1fr));
              row-gap: 16px;
              column-gap: 16px;
              height: 100%;

              @media (max-width: $breakpoint_mobile_max) {
                grid-template-columns: repeat(1, minmax(0, 1fr));
                column-gap: 0px;
              }

              .available-stake {
                display: flex;
                flex-direction: column;

                &-title {
                  display: flex;
                  justify-content: space-between;
                }

                .minimum-staking {
                  .title {
                    float: left;
                  }

                  .balance {
                    margin-left: 4px;
                    float: left;
                  }

                  p {
                    margin: 0;
                  }
                }

                .balance {
                  font-weight: 700;
                  line-height: 24px;
                  color: $color-text_content;
                  margin-bottom: 12px;
                }

                .stake-amount {
                  display: flex;
                  flex: 1;
                  flex-direction: column;
                  justify-content: space-between;
                }
              }

              .staked-amount {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;

                .unstake-amount {
                  display: flex;
                  flex: 1;
                  flex-direction: column;
                  justify-content: space-between;
                }
              }
            }

            .title {
              font-weight: 700;
              line-height: 24px;
              color: $color-text_content;
              margin-bottom: 18px;
            }

            .btn-stake,
            .btn-unstake {
              width: 100%;
              height: 40px;

              @media (max-width: 767px) {
                margin: 0px auto;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 12px;
              }
            }
          }

          .content--right {
            padding: 0px 0px 0px 28px;
            width: 100%;

            @media (max-width: $breakpoint_desktop_max) {
              padding-left: 12px;
            }

            @media (max-width: 767px) {
              padding: 0px;
            }

            p {
              margin: 0px;
            }

            .title {
              font-size: 14px;
              font-weight: 700;
              line-height: 24px;
              color: $color-text_content;
              margin-bottom: 13px;

              b {
                color: $color_primary;
              }
            }

            .btn-claim {
              width: 100%;
              height: 40px;

              @media (max-width: 767px) {
                margin: 0px auto;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 12px;
              }
            }

            .total-rewards {
              font-size: 28px;
              line-height: 40px;
              font-weight: 700;
              color: $color-text_content;
              letter-spacing: 0.04em;

              @media (max-width: 767px) {
                text-align: center;
              }

              &>div {
                display: inline-block;
                max-width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                span:first-child {
                  word-break: break-all;
                }
              }
            }

            .total-reward {
              color: $color_white;
              margin-bottom: 16px;

              display: flex;
              justify-content: flex-start;
              height: 100%;
              column-gap: 15px;

              &>div {
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                @media (max-width: 750px) {
                  width: 100%;
                }

                &:last-child {
                  @media (max-width: 767px) {
                    margin-top: 24px;
                  }
                }
              }

              .claim-reward {
                flex: 1;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
              }

              .full-width {
                width: 100%;
              }

              .claim-nft {
                align-items: center;
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .title {
                  max-width: 100%;
                  margin-bottom: 6px;
                  line-height: 24px;
                  font-weight: 700;
                }

                .earn-nft {
                  margin: 0 auto;
                  cursor: pointer;
                  display: block;
                }

                .ant-image {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  max-width: 140px;
                  max-height: 100px;
                  margin-bottom: 20px;
                }

                .nft-image {
                  border-radius: 5px;
                  width: auto;
                  height: auto;
                  max-height: 100px;
                  max-width: 100%;
                  object-fit: cover;
                  margin-left: auto;
                  margin-right: auto;
                }

                .empty-box {
                  max-height: 100px;
                  margin-bottom: 20px;
                }
              }

              @media (max-width: 750px) {
                display: block;
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }

    .rotate-180 {
      .ant-collapse-header {
        &::after {
          content: url('../../resources/svg/icon-show-180deg.svg');
          background: transparent;
          border-radius: 0px;
          width: 100%;
          position: absolute;
          bottom: 0;
          text-align: center;
        }
      }

      .ant-collapse-content {
        .ant-collapse-content-box {
          padding: 0;

          .content-item {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }
        }
      }
    }
  }
}

.apr-popover {
  max-width: 506px;

  .ant-popover-title {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    border: none;
    color: $color_text_black;
    padding-left: 64px;
    padding-right: 64px;
    padding-top: 30px;
  }

  .ant-popover-inner {
    border-radius: 12px;

    &-content {
      padding-left: 64px;
      padding-right: 64px;
    }
  }

  &__info {
    display: flex;
    width: 100%;
    justify-content: space-between;

    span {
      font-size: 16px;
      color: $color_text_black;
      margin: 0;
    }

    &:first-child {
      margin-bottom: 12px;
    }
  }

  &__title {
    font-weight: 700;
    color: $color_primary;
    font-size: 16px;
    margin: 0;
  }

  &__usd {
    text-align: right;
    font-size: 12px;
    color: $color-text_content;
  }

  &__content {
    padding-left: 12px;

    li {
      color: $color-text_content;
      font-weight: 700;
      line-height: 24px;
    }
  }
}

.list-nft {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  row-gap: 32px;
  column-gap: 20px;
  margin-bottom: 20px;

  @media (max-width: $breakpoint_large_desktop_max) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @media (max-width: $breakpoint_desktop_max) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (max-width: $breakpoint_tablet_max) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (max-width: $breakpoint_mobile_max) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  &__item {
    max-width: 100%;

    .ant-image {
      width: 100%;
      height: 248px;
      display: flex;
      align-items: center;
      justify-content: center;

      &-img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        border-radius: 8px;
      }
    }

    span {
      text-align: center;
      margin: auto;
      display: block;
      max-width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }
  }
}