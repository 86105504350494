.home-page {
  .home_container {
    width: 100%;
  }

  .summary-warp {
    position: relative;

    @media (max-width: $breakpoint_tablet_max) {
      &::before {
        content: '';
        border: none;
      }

      &::after {
        content: '';
        border: none;
      }
    }

    @media (width: $breakpoint_tablet_max) {}
  }

  &--sumary {
    width: calc(100% - 36px);
    padding: 0px 90px 32px 90px;
    color: $color_white;
    margin: 0px auto !important;

    transition: 0.2s ease;

    @media (max-width: 1024px) {
      width: calc(100% - 65px);
      padding: 0px 0px 32px;
    }


    @media (max-width: $breakpoint_tablet_max) {
      width: 100%;
      height: fit-content;
    }

    @media (max-width: 408px) {
      background-size: 100% 100%;
      min-height: 412px;
    }

    .ant-col {
      &:nth-child(1) {
        div {
          background-image: url('../../resources/images/lock-icon.png'), $color_pink_gradient;
        }
      }

      &:nth-child(2) {
        div {
          background-image: url('../../resources/images/bookmark-icon.png'), $color_pink_gradient;
        }
      }

      &:nth-child(3) {
        div {
          background-image: url('../../resources/images/ttx-icon.png'), $color_pink_gradient;
        }
      }
    }



    .ant-col>div {
      text-align: center;
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: flex-start;
      position: relative;
      border-radius: 12px;
      min-height: 124px;
      align-items: center;
      background-position: left, top;
      background-repeat: no-repeat;

      @media(max-width: $breakpoint_tablet_max) {
        max-width: 326px;
        width: 100%;
      }

      img {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      &:last-child {
        border-right: none;

        @media (max-width: $breakpoint_tablet_max) {
          &::after {
            border: none;
          }

          margin-bottom: 0px;
        }
      }

      @media (max-width: $breakpoint_desktop_max) {
        margin: auto;
        padding-left: 52px;
      }

      @media (max-width: $breakpoint_tablet_max) {
        border-right: none;
        margin-bottom: 32px;
        padding-left: 70px;
      }

      @media (max-width: $breakpoint_mobile_max) {
        padding-left: 0;
        align-items: center;
      }

      @media (width: $breakpoint_tablet_max) {
        margin-bottom: 0px;
        border-right: 1px dashed $color_titan_white;

        &::after {
          content: '';
          display: none;
        }
      }

      &:last-child {
        @media (max-width: $breakpoint_mobile_max) {
          margin-bottom: 0;
        }
      }

      .title {
        z-index: 2;
        text-align: start;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 8px;

        @media (max-width: $breakpoint_tablet_max) {
          font-size: 14px;
          font-weight: 600;
        }
      }

      .amount {
        z-index: 1;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        text-transform: uppercase;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 92%;

        @media (max-width: $breakpoint_tablet_max) {
          font-size: 24px;
          line-height: 60px;
        }

        // @media (max-width: $breakpoint_mobile_max) {
        //   font-size: 24px;
        //   line-height: 32px;
        // }
      }
    }

    p {
      margin: 0px;
    }
  }

  .staking-list {
    margin-top: 60px;
    display: flex;
    justify-content: space-around;
    column-gap: 16px;

    .ant-col {
      width: 377px;
      border-radius: 10px;
      padding: 36px 32px;
      margin-bottom: 32px;
      color: $color_big_stone;
      display: flex;
      justify-content: center;
      flex-direction: column;
      background-image: url('../../resources/images/bg-item-home.png');
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;

      @media (max-width: $breakpoint_mobile_max) {
        padding: 34px 22px;
      }

      .title {
        font-size: 24px;
        text-align: center;
      }

      .content {
        font-size: 12px;
        line-height: 24px;
        text-align: center;
        max-width: 317px;
      }
    }

    .token-staking {
      .staking {
        object-fit: contain;
        max-height: 116px;
      }

      p {
        margin: 0px;
      }

      .title {
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
        color: $color_porcelain;
        margin-top: 13px;
      }

      .content {
        line-height: 24px;
        color: $color_athens_gra;
        margin-top: 12px;
        max-width: 312px;
        font-size: 14px;
      }

      .btn-group {
        display: flex;
        justify-content: space-between;
        margin-top: 22px;

        @media (max-width: $breakpoint_mobile_max) {
          display: inline-block;
          flex-direction: column;
        }

        button {
          height: 40px;
          width: 144px;
          line-height: 16px;
          font-weight: 700;

          @media (max-width: $breakpoint_mobile_max) {
            width: 100%;

            &:last-child {
              margin-top: 12px;
            }
          }
        }

        // .lp-staking {
        //   border: 1px solid $color_cornflower_blue;
        //   color: $color_white;
        // }
      }
    }

    .dort-staking {
      background-size: contain;
      background-image: url('../../resources/images/bg-item-dort.png');
      padding: 0px;
      display: flex;
      align-items: center;

      img {
        max-width: 322px;
        max-height: 316px;
        background-size: cover;
        margin-top: 73px;
      }

      .triangle-large,
      .triangle-medium,
      .triangle-small,
      .screw {
        position: absolute;
      }

      .triangle-large {
        top: -62px;
        left: 195px;
      }

      .triangle-medium {
        top: 98px;
        left: 26px;
      }

      .triangle-small {
        top: 94px;
        right: 36px;
      }

      .screw {
        bottom: -14px;
        left: 65px;
      }
    }

    .nft-staking {
      align-items: center;

      button {
        height: 40px;
        // margin-top: 22px;
      }

      .staking {
        object-fit: contain;
        max-height: 116px;
      }

      p {
        margin: 0px;
      }

      .title {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        color: $color_porcelain;
        margin-top: 13px;
      }

      .content {
        line-height: 24px;
        color: $color_athens_gra;
        margin-top: 12px;
        max-width: 312px;
        margin-bottom: 22px;
      }
    }
  }
}