.nft-wrap {
  padding: 12px;
  background-image: url('../../resources/images/bg-dort.png');
  background-position: center top;
  background-size: 100% 268px;
  background-repeat: no-repeat;
  border-radius: 12px;
  // width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px auto;
  position: relative;
  width: 202px;
  height: 274px;

  @media (max-width: 812px) {
    width: 182px;
  }

  @media (max-width: 664px) {
    width: 202px;
  }

  @media (max-width: $breakpoint_mobile_max) {
    background-image: url('../../resources/images/bg-dort-mobile.png');
    width: 283px;
    background-size: 100% 125px;
    flex-direction: row;
    height: 125px;
    padding: 18px 20px;
  }

  &::before {
    content: '';
    width: 122px;
    height: 136px;
    top: 82px;
    transform: translate(2%, 0%);
    position: absolute;
    background-image: url('../../resources/images/image-dort-behind.png');
    background-repeat: no-repeat;
    background-size: contain;

    @media (max-width: $breakpoint_mobile_max) {
      width: 52px;
      height: 52px;
      top: 55px;
      left: 24px;
    }
  }

  .content {
    width: 100%;
    position: absolute;
    bottom: 18px;

    .title-nft {
      padding: 0px 12px;
      width: 100%;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: uppercase;
      z-index: 1;
      font-size: 16px;
    }
  }
  .level {
    font-weight: 600;
    line-height: 21px;
    color: #b1b5c3;
    margin-top: -6px;
    margin-bottom: 14px;

    &::after {
      position: absolute;
      content: '';
      top: 30px;
      left: 78px;
      width: 47px;
      height: 2px;

      background: #ffffff;
      box-shadow: 0px 33px 80px rgba(255, 196, 17, 0.85), 0px 13.7866px 33.4221px rgba(255, 196, 17, 0.611026),
        0px 7.37098px 17.869px rgba(255, 196, 17, 0.506691), 0px 4.13211px 10.0172px rgba(255, 196, 17, 0.425),
        0px 2.19453px 5.32008px rgba(255, 196, 17, 0.343309), 0px 0.913195px 2.21381px rgba(255, 196, 17, 0.238974);
      border-radius: 0px 0px 5px 5px;
      @media (max-width: 812px) {
        left: 68px;
      }

      @media (max-width: $breakpoint_mobile_max) {
        top: 62px;
        left: 97px;
        width: 40px;
      }
    }
  }
  .image-nft {
    // margin-top: 39px;
    z-index: 1;
    width: 100%;
    max-width: 160px;
    max-height: 152px;
    height: 100%;
    object-fit: cover;

    @media (max-width: $breakpoint_mobile_max) {
      max-width: 62px;
      max-height: 82px;
      margin-right: 14px;
    }
  }

  .clan {
    padding: 2px 18px;
    line-height: 18px;
    font-size: 12px;
    font-weight: 600;
    color: #ffa800;
    width: fit-content;
    height: 22px;
    border: 1px solid #ffa800;
    margin: 0px auto;
    display: flex;
    align-items: center;
    background: linear-gradient(
      180deg,
      rgba(255, 153, 0, 0.1) -4.55%,
      rgba(255, 168, 0, 0.1) 55.11%,
      rgba(255, 195, 1, 0.1) 104.55%
    );
    border-radius: 8px;

    @media (max-width: $breakpoint_mobile_max) {
      margin: 0;
    }
  }

  p {
    margin: 0px;
  }

  .title-nft {
    color: $color_white;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 6px;
    margin-top: 10px;
    min-height: 24px;
    z-index: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;

    @media (max-width: $breakpoint_mobile_max) {
      margin: 0px;
      line-height: 18px;
      margin-bottom: 4px;
    }
  }

  .group-level {
    display: flex;
    justify-content: space-between;
  }

  .tag-list {
    display: flex;
    margin-bottom: 20px;
    justify-content: center;

    @media (max-width: 414px) {
    }

    .tag {
      min-height: 24px;
      padding: 6px;
      background: $color_melrose;
      margin-right: 6px;
      border-radius: 3px;
      font-size: 10px;
      font-weight: 700;
      line-height: 12px;
      color: $color_woodsmoke;

      &:last-child {
        margin-right: 0px;
      }

      @media (max-width: 414px) {
        font-size: 8px;
        line-height: 12px;
        margin: 0px 6px 0px 0px;
      }
    }
  }
}
.btn-choose {
  max-width: 147px;
  margin-top: 16px;
  background-size: 100% 38px !important;

  &:hover {
    background-size: 100% 38px;
  }

  @media (max-width: $breakpoint_mobile_max) {
    max-width: 186px;
    margin-top: 6px;
  }

  @media (max-width: 414px) {
    font-size: 14px;
    line-height: 16px;
  }
}

.btn-selected {
  max-width: 147px;
  margin-top: 16px;
  background-size: 100% 38px !important;
  background-image: url('../../resources/images/bg-btn-selected.png') !important;

  &:hover {
    background-size: 100% 38px;
    background-image: url('../../resources/images/bg-btn-selected.png');
  }

  @media (max-width: $breakpoint_mobile_max) {
    max-width: 186px;
    margin-top: 6px;
  }

  @media (max-width: 414px) {
    font-size: 14px;
    line-height: 16px;
  }
}

.nft-selected {
  background-image: url('../../resources/images/bg-dort-choose.png');
  background-position: center top;
  background-size: 100% 268px;
  background-repeat: no-repeat;

  @media (max-width: $breakpoint_mobile_max) {
    background-image: url('../../resources/images/bg-dort-choose-mobile.png');
    width: 283px;
    background-size: 100% 125px;
    flex-direction: row;
    height: 125px;
    padding: 18px 20px;
  }

  .tag-list {
    justify-content: center;

    .tag {
      background: $color_white;
    }
  }
}

.comingsoon {
  position: relative;
}

.comingsoon:before {
  content: '';
  position: absolute;
  opacity: 0.2;
  border-radius: 12px;
  cursor: not-allowed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../../resources/images/bg-dort-disabled.png');
  background-position: center top;
  background-size: 100% 268px;
  background-repeat: no-repeat;
}
