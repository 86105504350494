@import 'variables';
@import 'common';

.container {
  height: 100%;
  max-width: 1210px;
  padding: 0px 20px;
  margin: 0px auto;
  @media (max-width: $breakpoint_mobile_max) {
    padding: 0px 14px;
  }
}

.layout {
  background: $color_mako;
  min-height: 100vh;
  background-color: $color_bg_body;
  background-image: url('../resources/images/bg-pink.png');
  background-size: auto;
  background-attachment: fixed;
  background-position: bottom;
  background-repeat: no-repeat;
  .ant-layout {
    background: transparent;
    &-content {
      margin-top: 124px;
      @media (max-width: $breakpoint_mobile_max) {
        margin-top: 100px;
      }
    }
  }
}
