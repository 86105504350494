.ant-modal {
  .ant-modal-content {
    background-color: $color_white;
    border-radius: 12px;
    padding: 32px 32px 42px 32px;

    @media (max-width: $breakpoint_mobile_max) {
      padding: 24px 12px;
    }

    .btn-wrapper {
      background: $color_pink_gradient;
      padding: 2px;
      border-radius: 12px;
      // margin-top: 28px;

      &:last-child {
        margin-top: 8px;

        .btn {
          color: $color_wallet_connect;
        }
      }
    }

    .active {
      background: $color_shamrock;
      position: relative;

      &::after {
        position: absolute;
        content: url('../../resources/svg/icon_checked.svg');
        top: 4px;
        right: 8px;
      }
    }

    .ant-modal-body {
      padding: 0;
    }

    .group-btn {
      display: flex;
      column-gap: 20px;
      width: 100%;
    }

    .content {
      font-size: 14px;
      line-height: 21px;
      color: $color-text_content;
      margin-bottom: 32px;
    }

    .connect_wallet {
      font-family: $font-DMSans-Regular;
      max-width: 432px;
      margin: 0px auto;
      padding: 0px 16px;

      .desc {
        margin-bottom: 12px;
      }

      .label {
        font-weight: bold;
        line-height: 24px;
      }

      h1 {
        font-size: 24px;
        line-height: 32px;
        font-weight: 700;
        margin-bottom: 12px;
        text-align: center;
        color: $color_text-content;
      }

      p {
        line-height: 24px;
        text-align: center;
        color: $color_text-content;
        max-width: 400px;
        margin: 0px auto;
      }

      .btn {
        display: flex;
        height: 60px;
        align-items: center;
        width: 100%;
        position: relative;
        padding: 12px 23px;
        line-height: 24px;
        font-size: 16px;
        font-weight: 700;
        color: $color_orange;
        border-radius: 10px;
        background-clip: padding-box;
        border: none;

        img {
          margin-right: 12px;
        }

        &__icon-right {
          position: absolute;
          right: 12px;
        }
      }

      .chains-network {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 16px;
        margin-bottom: 22px;

        .btn-wrapper {
          margin-top: 0;
        }

        .chain {
          border: none;
          padding: 8px 14px;
          border-radius: 12px;
          color: #912066;

          span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          img {
            margin-right: 6px;
          }
        }
      }
    }

    .amount-token {
      font-size: 28px;
      line-height: 40px;
      color: $color_primary;
      font-weight: 700;
      margin-bottom: 24px;
    }

    .tx-hash {
      color: #ffffff;
      line-height: 24px;
      margin-bottom: 32px;

      &::before {
        content: 'TxHash:';
        margin-right: 18px;
      }

      .ant-typography-copy {
        margin-top: 28px;

        path {
          fill: #ffffff;
        }
      }
    }

    button {
      height: 40px;
    }
  }
}

.model-change-network {
  .switch-network {
    .title {
      margin-bottom: 20px;
      font-size: 20px;
      font-weight: 600;
      color: $color_black_1;
      text-align: center;
    }
  }

  .chains-network {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 16px;

    .btn-wrapper {
      min-height: 50px;
      height: 100%;

      &:last-child {
        margin: 0;

        .btn {
          color: #912066;
        }
      }

      .chain {
        border-radius: 10px;
        width: 100%;
        min-height: 50px;
        height: 100%;
        display: flex;
        align-items: center;
        color: #912066;
        font-weight: bold;

        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        img {
          margin-right: 6px;
        }
      }
    }
  }
}

.model-metamask_notfound {
  .ant-modal-content {
    background: $color_white;
  }

  .metamask_notfound {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      margin-bottom: 20px;
      font-size: 20px;
      font-weight: 600;
      color: $color_black_1;
    }

    >img {
      max-width: 65px;
      margin-bottom: 30px;
    }

    >a {
      color: $color_status_blue_text;
      display: inline-flex;
      align-items: center;

      >svg {
        margin-right: 5px;
      }
    }

    .ant-spin-lg {
      .ant-spin-dot {
        font-size: 80px;
      }
    }
  }
}

.model-claim-reward {
  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-content,
  .ant-modal-body {
    width: 100%;
    background-color: $color_white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  p {
    text-align: center;
  }

  .title-claime-reward {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: $color-text_content;
    margin-bottom: 12px;
  }

  .receive {
    font-weight: 400;
    line-height: 24px;
    color: $color-text_content;
    margin-bottom: 20px;
  }

  .claim-content {
    font-size: 16px;
    text-align: center;

    p {
      margin-top: 24px;
    }
  }

  .amount-token {
    font-size: 28px;
    line-height: 40px;
    color: #dfa920;
    margin-bottom: 20px;
    font-weight: 700;
    margin-bottom: 32px;

    div {
      margin: auto;
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      span {
        text-align: center;

        &:first-child {
          word-break: break-all;
        }
      }
    }
  }

  .btn-claim {
    width: 80%;
    max-width: 320px;
    height: 40px;
    margin-bottom: 24px;

    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus,
    &[disabled]:active {
      background-color: transparent;
    }
  }
}

.model-confirm-unstake {
  p {
    margin: 0;
    text-align: center;
  }

  .ant-modal-content {
    border: 1px solid #343434;
    background-color: $color_white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title-confirm {
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: $color_text-content;
    margin-bottom: 16px;
  }

  .content {
    font-size: 14px;
    line-height: 21px;
    color: $color-text_content;
    margin-bottom: 32px;
  }

  .group-btn {
    display: flex;
    column-gap: 20px;

    .btn-confirm {
      background-color: #6f22e4;
      color: #ffffff;
    }

    .btn-cancel {
      color: #6f22e4;
      border: 2px solid #6f22e4;
    }

    button {
      min-width: 150px;
      padding: 12px 16px;
      border-radius: 30px;
      font-weight: 600;
      height: 44px;
    }
  }
}

.network_notice {
  text-align: center;
  padding: 32px 0;

  .title {
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
    margin-top: 32px;

    &.ant-typography {
      margin-bottom: 16px;
    }
  }

  .ant-spin-dot {
    font-size: 80px;
  }

  a {
    cursor: default;
  }
}

.claim-nft-modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-size: 32px;
    line-height: 32px;
    font-weight: 700;
  }

  .nft-name {
    margin-top: 16px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  img {
    border-radius: 5px;
    max-height: 300px;
    max-width: 100%;
    margin: 0 auto;
  }

  .nft-actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

    .later {
      cursor: pointer;
      font-weight: 700;
      color: $color_secondary;
      margin-top: 8px;
    }
  }

  .next-time {
    margin-top: 20px;
    text-align: center;
  }
}

.staking-tier-modal {
  .ant-modal-content {
    .close-icon {
      position: absolute;
      top: 21px;
      right: 21px;
      height: fit-content;
      width: fit-content;
      cursor: pointer;

      svg {
        width: 14px;
        height: 14px;
      }
    }

    .modal-header {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__title {
        font-size: 24px;
        line-height: 32px;
        font-weight: 700;
        margin-bottom: 4px;
      }

      &__content {
        line-height: 24px;
      }
    }

    .ant-tabs {
      &-nav {
        .ant-tabs-tab {

          &:hover,
          &:focus,
          &:active {
            color: $color_primary;
          }

          &-active {
            color: $color_primary;

            .ant-tabs-tab-btn {
              color: $color_primary;
            }
          }

          font-size: 18px;
          font-weight: 700;
        }

        .ant-tabs-ink-bar {
          background-color: $color_primary;
        }
      }
    }

    .required-amount {
      color: $color_primary;
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
    }
  }
}

.nft-detail-modal {
  .close-icon {
    width: 14px;
    height: 14px;
    position: absolute;
    top: 21px;
    right: 21px;
  }

  &__body {
    display: flex;

    img {
      flex-basis: 50%;
    }
  }

  &__content {
    padding: 20px 0px 20px 44px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__name {
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    margin-bottom: 16px;
    color: black;
  }

  &__property {
    font-size: 16px;
    margin-bottom: 4px;
    font-weight: 700;
    color: black;

    &--value {
      font-size: 16px;
      font-weight: 700;
      color: $color_text_blur;
    }
  }

  .ant-typography {
    display: flex;
  }
}