// size screen responsive
// Small devices (landscape phones, 576px and up)
// Extra large devices (large desktops, 1200px and up)
// Large devices (desktops, 992px and up)
// Medium devices (tablets, 768px and up)
$breakpoint_mobile_max: 576px;
$breakpoint_tablet_max: 768px;
$breakpoint_desktop_max: 993px;
$breakpoint_large_desktop_max: 1200px;
$breakpoint_ultra_desktop_max: 1600px;

// font

$font-DMSans-Bold: 'DMSans-Bold';
$font-DMSans-BoldItalic: 'DMSans-BoldItalic';
$font-DMSans-Italic: 'DMSans-Italic';
$font-DMSans-Medium: 'DMSans-Medium';
$font-DMSans-MediumItalic: 'DMSans-MediumItalic';
$font-DMSans-Regular: 'DMSans-Regular';
$font-Neumatic-Bold: 'Neumatic Compressed Bold';
$font-Blinker-Regular: 'Blinker-Regular';

@font-face {
  font-family: $font-DMSans-Bold;
  src: url('../../src/resources/fonts/DMSans-Bold.ttf');
}

@font-face {
  font-family: $font-DMSans-BoldItalic;
  src: url('../../src/resources/fonts/DMSans-BoldItalic.ttf');
}

@font-face {
  font-family: $font-DMSans-Italic;
  src: url('../../src/resources/fonts/DMSans-Italic.ttf');
}

@font-face {
  font-family: $font-DMSans-Medium;
  src: url('../../src/resources/fonts/DMSans-Medium.ttf');
}

@font-face {
  font-family: $font-DMSans-MediumItalic;
  src: url('../../src/resources/fonts/DMSans-MediumItalic.ttf');
}

@font-face {
  font-family: $font-DMSans-Regular;
  src: url('../../src/resources/fonts/DMSans-Regular.ttf');
}

@font-face {
  font-family: $font-Neumatic-Bold;
  src: url('../../src/resources/fonts/Neumatic-Compressed-Semi-Bold.otf');
}

@font-face {
  font-family: $font-Blinker-Regular;
  src: url('../../src/resources/fonts/Blinker-Regular.ttf');
}

//Color
$color_bg_header: #101010;
$color_cornflower_blue: #6f22e4;
$color_white: #ffffff;
$color_big_stone: #121d37;
$color_paua: #1c006d;
$color_fog: #ddd8ff;
$color_electric_violet: #4b00ea;
$color_electric_violet_disable: #4b00ea80;
$color_tolopea: #10003f;
$color_porcelain: #f4f5f6;
$color_athens_gra: #e6e8ec;
$color_rrench_rose: #ef466f;
$color_cadet_blue: #b1b5c3;
$color_kingfisher_daisy: #28009b;
$color_mirage: #220267;
$color_golden_grass: linear-gradient(180deg, #222230 0%, #ff00a3 100%);
$color_bright_turquoise: #08f2f1;
$color_waterloo: #7b7791;
$color_melrose: #9b8bff;
$color_woodsmoke: #141416;
$color_french_rose: #ef466f;
$color_kingfisher_daisy_1: #260081;
$color_status_blue_text: #3772ff;
$color_black_1: #23262f;
$color_mirage: #1a1929;
$color_mako: #141321;
$color_titan_white: #b1b5c3;

//new color
$color_pink_gradient: linear-gradient(96.23deg, #912066 0%, #c52b8b 75.46%);
$color_bg_body: #fcfcfc;
$color_text-content: #212121;
$color_orange: #f5841f;
$color_wallet_connect: #00f0ff;
$color_primary: #c52b8b;
$color_support: #452438;
$color_secondary: #d13724;
$color_neutral: #fcfcfd;
$color_input: #4d4d4d;
$color_placeholder: #c9c9c9;
$color_text_black: #060a13;
$color_text_blur: #4f5566;
$color_shamrock: #32C671;