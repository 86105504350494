.input {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  &__label {
    color: $color_white;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;

    &--level {
      &-item {
        height: 4px;
        width: 10px;
        background-color: black;
        display: inline-block;
        margin-right: 6px;
      }
    }

    &--low {
      background-color: orange;
      color: orange;
    }

    &--medium {
      background-color: pink;
      color: pink;
    }

    &--high {
      background-color: red;
      color: red;
    }

    &--level {
      &-title {
        background-color: transparent;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  &__wrap-input-icon.ant-btn {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;

    &:focus,
    &:hover {
      background-color: transparent;
      border: none;
    }
  }

  &__message {
    font-size: 12px;
    color: #ef466f;
    font-weight: 500;
    margin-top: 5px;
  }

  input {
    padding: 0px 16px;
    background-color: transparent;
    box-shadow: none;
    border: none;
    transition: 0.2s;
    color: $color_input;
    flex: 1;

    &:focus {
      outline: none;
      border-bottom: none;
    }

    &::placeholder {
      color: $color_placeholder;
    }

    &[name='password'],
    &[name='rePassword'],
    &[name='confirm_password'],
    &[name='oldPassword'],
    &[name='newPassword'],
    &[name='confirmPassword'] {
      padding-right: 40px;
    }
  }

  &__wrap-input {
    display: flex;
    align-items: center;
    position: relative;
    height: 37px;
    background: transparent;
    border-radius: 5px;
    border: 1px solid $color_athens_gra;

    &_border {
      input {
        border: 1px solid black;
        border-radius: 10px;
        padding: 15px;
        color: black;

        &:focus {
          border-color: red;
        }
      }
    }

    .input__button {
      background: $color_pink_gradient;
      border-radius: 90px;
      height: 32px;
      width: 53px;
      border: none;
      margin-right: 2.5px;
      cursor: pointer;
      color: $color_neutral;
      font-weight: 700;
      line-height: 16px;
      font-size: 14px;
      padding: 4px 14px;
      position: absolute;
      right: 0px;
    }
  }

  .ant-input-number {
    width: 100%;
    background-color: transparent;
    box-shadow: none;
    display: flex;
    align-items: center;
    padding: 0px 16px;
    border: none;

    .ant-input-number-handler-wrap {
      display: none;
    }

    .ant-input-number-input-wrap {
      width: 100%;

      input {
        border: none;
        height: 20px;
        padding: 0;
        border-radius: 0;
        font-size: 14px;
      }
    }
  }
}