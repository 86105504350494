@import 'variables';
@import 'common';

.ant-layout-header {
  background: $color_white;
  height: fit-content;
  padding: 8px 0px;
  position: fixed;
  z-index: 1;
  width: 100%;
  transition: all 0.3s;
  backface-visibility: hidden;
  z-index: 11;
  height: 72px;
  border-bottom: 1px solid $color_athens_gra;
}

.header.header-tiny {
  padding: 4px 0px;

  .header--left {
    .logo {
      width: 46px;
    }
  }
}

.divide {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .header--left {
    display: flex;
    cursor: pointer;

    .ant-menu {
      display: flex;
      color: $color_fog;
      background: transparent;
      font-weight: 700;
      border: none;
      align-items: center;

      .ant-menu-title-content {
        font-family: $font-DMSans-Bold;
      }

      &-item {
        &:hover {
          color: $color_fog;
        }

        &:active {
          background: transparent;
        }
      }

      &-item:hover::after {
        border-bottom: none;
      }
    }

    .logo {
      object-fit: contain;
      margin-right: 5px;
      width: 52px;
      transition: all 0.3s;
      backface-visibility: hidden;
    }

    .brand {
      font-style: italic;
      color: $color_primary;
      font-weight: 700;
      font-size: 18px;
      line-height: 23.44px;
      margin: 0;
      display: flex;
      align-items: center;
    }
  }

  .header--center {
    display: flex;
    font-family: $font-DMSans-Regular;
    align-items: center;
    height: 100%;

    span {
      color: $color_text-content;
      line-height: 24px;
      font-weight: 700;
      cursor: pointer;

      a {
        color: $color_text-content;
      }

      &:first-child {
        margin-right: 48px;
      }
    }
  }

  .address-wallet {
    height: 48px;
    width: 174px;
    margin-left: 24px;

    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;

    .wrap {
      &::before {
        content: '';
        position: absolute;
        top: -1px;
        width: 135px;
        height: 24px;
        border-top: 2px solid transparent;
        border-left: 2px solid transparent;
        border-right: 2px solid transparent;
        transform: perspective(10px) rotateX(6deg);
        background: linear-gradient(to bottom, #1a1929, #1a1929), linear-gradient(to bottom, #fc00a4, #b51cd6);
        background-clip: padding-box, border-box;
        background-origin: padding-box, border-box;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: -2px;
        width: 135px;
        height: 24px;
        border-top: 2px solid transparent;
        border-left: 2px solid transparent;
        border-right: 2px solid transparent;
        transform: perspective(10px) rotateX(174deg);
        background: linear-gradient(to top, #1a1929, #1a1929), linear-gradient(to top, #b51cd6, #762de0);
        background-clip: padding-box, border-box;
        background-origin: padding-box, border-box;
      }

      &-mobile {
        &::before {
          top: 0;
          width: 302px;
          transform: perspective(10px) rotateX(3deg);
        }

        &::after {
          bottom: -1px;
          width: 302px;
          transform: perspective(10px) rotateX(177deg);
        }
      }
    }

    .content {
      position: absolute;
      margin-left: -4px;
      width: 100%;
      height: 36px;
      line-height: 21px;
      font-weight: 700;
      line-height: 16px;
      font-family: $font-DMSans-Bold;
      color: $color_cadet_blue;
      // background-image: url('../resources/images/border-address-wallet.png');
      background: $color_pink_gradient;
      background-size: cover;
      display: flex;
      align-items: center;
      // padding: 10px 30px;
      justify-content: center;
      border-radius: 36px;

      &-bg {
        // background-image: url('../resources/images/border-address-wallet.png'),
        //   url('../resources/images/bg-address-wallet.png');
      }

      &-mobile {
        background-size: 310px;
        background-image: url('../resources/images/border-address-wallet-mobile.png');

        &-bg {
          background-image: url('../resources/images/border-address-wallet-mobile.png'),
            url('../resources/images/bg-address-wallet-mobile.png');
        }
      }
    }
  }

  .header--right {
    display: flex;
    align-items: center;

    p {
      margin: 0px;
    }

    .network--selected {
      // margin-right: 25px;
      padding: 7px 14px;
      background: $color_black_1;
      color: $color_white;
      line-height: 16px;
      font-weight: bold;
      display: flex;
      align-items: center;
      border-radius: 36px;
      cursor: pointer;

      img {
        margin-right: 4px;
      }


    }

    .content-bg {
      .btn-connect {
        background: transparent;
      }

      .ant-space-item {
        display: flex;
      }
    }

    .btn-connect {
      height: 46px;
      width: 100%;
      line-height: 21px;
      font-weight: 700;
      line-height: 16px;
      font-family: $font-DMSans-Bold;
    }

    .ant-popover-placement-bottom {
      padding-top: 0;
    }

    .ant-popover {
      min-height: 165px;
      min-width: 188px;
      margin-top: 12px;
      margin-left: -4px;

      .ant-popover-arrow {
        display: none;
      }

      .ant-popover-inner {
        background-color: $color_white;
        border-radius: 20px;

        min-height: 100px;
        min-width: 188px;
        font-family: $font-DMSans-Regular;

        .ant-popover-inner-content {
          padding: 12px 26px;
        }

        .wrap-info-header {
          position: relative;
          font-weight: 700;

          .ant-typography {
            margin: 0;
            color: $color_text-content;
            display: flex;
            align-items: center;
            line-height: 21px;
            margin-bottom: 8px;

            .ant-typography-copy {
              display: flex;

              svg {
                margin-left: 8px;

                path {
                  fill: #b1b5c3;
                }
              }

              // &::after {
              //   content: '';
              //   right: 0;
              //   top: 7px;
              //   height: 10.84px;
              //   width: 2.27px;
              //   position: absolute;
              //   background: #b1b5c3;
              //   box-shadow: 0px -2px 80px rgba(231, 233, 255, 0.85),
              //     0px -0.835552px 33.4221px rgba(231, 233, 255, 0.611026),
              //     0px -0.446726px 17.869px rgba(231, 233, 255, 0.506691),
              //     0px -0.250431px 10.0172px rgba(231, 233, 255, 0.425),
              //     0px -0.133002px 5.32008px rgba(231, 233, 255, 0.343309),
              //     0px -0.0553451px 2.21381px rgba(231, 233, 255, 0.238974);
              //   border-radius: 0px 0px 5px 5px;
              // }
            }

            // &::before {
            //   content: '';
            //   left: 0;
            //   height: 10.84px;
            //   width: 2.27px;
            //   position: absolute;
            //   background: #b1b5c3;
            //   box-shadow: 0px -2px 80px rgba(231, 233, 255, 0.85),
            //     0px -0.835552px 33.4221px rgba(231, 233, 255, 0.611026),
            //     0px -0.446726px 17.869px rgba(231, 233, 255, 0.506691),
            //     0px -0.250431px 10.0172px rgba(231, 233, 255, 0.425),
            //     0px -0.133002px 5.32008px rgba(231, 233, 255, 0.343309),
            //     0px -0.0553451px 2.21381px rgba(231, 233, 255, 0.238974);
            //   border-radius: 0px 0px 5px 5px;
            // }

            // &::after {
            //   content: '';
            //   width: 136px;
            //   height: 1px;
            //   position: absolute;
            //   left: 0;
            //   top: 28px;
            //   background-image: url('../resources/images/line-info.png');
            // }
          }

          .ant-space {
            cursor: pointer;
            border-radius: 4px;
            width: 100%;
            padding: 4px 0px 4px 4px;

            &:hover {
              background-color: $color_athens_gra;
            }

            &-item {
              display: flex;
            }
          }

          .ant-menu {
            border: none;
            background: transparent;

            li {
              margin-top: 12px !important;
            }

            li:nth-child(2) {
              height: 50px;
            }

            &-item {
              margin: 0;
              padding: 0;

              a {
                color: $color_cadet_blue;
              }

              .btn-disconnect {
                min-height: 36px;
              }

              img {
                margin-right: 8px;
              }

              &:active {
                background: transparent;
              }
            }
          }
        }
      }
    }

    .header-mobile {
      display: flex;
      align-items: center;

      svg {
        path {
          fill: $color_porcelain;
        }
      }

      .ant-drawer-wrapper-body {
        background-color: $color_bg_body;

        .ant-drawer-header {
          background-color: $color_bg_body;

          .ant-drawer-header-title {
            justify-content: end;
          }

          .ant-drawer-close {
            svg {
              width: 14px;

              path {
                fill: $color_black_1;
              }
            }
          }
        }
      }

      .ant-menu {
        border: none;
        background-color: $color_bg_body;

        padding-top: 42px;

        li,
        a {
          padding: 0;
          color: $color_text-content;
          font-weight: 700;
          line-height: 16px;

          &:hover {
            color: $color_primary;
          }
        }

        &-item:active,
        &-submenu-title:active {
          background: transparent;
        }
      }

      .address-wallet,
      .btn-connect {
        position: absolute;
        width: 310px;
      }
    }
  }
}