.switch-wrap {
  .ant-radio-group {
    background: $color_pink_gradient;
    border-radius: 36px;
    height: 40px;
    padding: 4px;
    display: flex;

    .ant-radio-button-wrapper {
      background: transparent;
      color: $color_white;
      border: none;
      border-radius: 36px;
      font-weight: 700;
      line-height: 18px;
      text-overflow: ellipsis;
      white-space: nowrap;

      span {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }

    .ant-radio-button-wrapper-checked {
      background: $color_white;
      border: none;
      border-radius: 20px;
      z-index: 0;

      span {
        color: $color_primary;
      }
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
      background: transparent;
    }

    .ant-radio-button-wrapper:not(:first-child)::before {
      background: transparent;
    }
  }
}