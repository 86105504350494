.modal-pagination {
  display: flex;
  justify-content: center;
  height: fit-content;
  .ant-pagination-item {
    font-weight: 700;
    border-radius: 4px;
    &-active {
      a {
        color: $color_primary;
      }
      border-color: $color_primary;
    }
    &:hover,
    &:active,
    &:focus {
      border-color: $color_primary;
    }
    a {
      &:hover,
      &:active,
      &:focus {
        color: $color_primary;
      }
    }
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    button {
      height: 100%;
      &:hover,
      &:active,
      &:focus {
        color: $color_primary;
        border-color: $color_primary;
      }
    }
  }
}
