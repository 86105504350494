.modal-confirm-success {
  .ant-modal-content {
    background-color: $color_white;
    border: 1px solid #343434;

    .ant-modal-body {
      padding: 0px;
      p {
        margin: 0px;
      }
      img {
        width: 62px;
        margin: 22px 0px;
      }
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .title {
        font-size: 32px;
        font-weight: 400;
        line-height: 40px;
        color: $color-text_content;
      }
      .content {
        margin-top: 16px;
        color: #9d9d9d;
        text-align: center;
        line-height: 21px;
      }
      .tx-hash {
        color: $color-text_content;
        line-height: 24px;
        &::before {
          content: 'TxHash:';
          margin-right: 18px;
        }
        .ant-typography-copy {
          margin-top: 28px;
          path {
            fill: $color_primary;
          }
        }
      }

      .button {
        width: 180px;
        height: 40px;
      }
    }
  }
}
